































import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

export default defineComponent({
  setup(_, { root }) {
    const model = reactive<{
      eventDegree: string[];
    }>({
      eventDegree: [],
    });

    const state = reactive({
      loading: false,
      success: false,
      error: false as boolean | number,
      empty: false,
      loaded: false,
      degrees: [],
    });

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return `${root.$tc("layout.errors.noPermission")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    // Start fetch degree items list

    const fetchDegree = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get("degree")
        .then(({ data: { degrees } }) => {
          state.empty = false;
          state.degrees = degrees;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.empty = true;
            state.degrees = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchDegree);

    // End fetch degree items list

    const onSubmit = async () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        degrees: model.eventDegree,
        eventId: root.$route.params.id,
      };

      state.loading = true;

      axiosInstance
        .post("degree/assign", data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.success = true;
          state.error = false;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "success",
            message: root.$tc("panel.event.degree.success"),
          });
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        })
        .finally(() => (state.loading = false));
    };

    // Fetch event

    const fetchEvent = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}`)
        .then(({ data: { event } }) => {
          model.eventDegree = event.degrees ?? [];
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchEvent);

    return {
      state,
      model,
      onSubmit,
    };
  },
});
